<template>
  <organization-detail :organization="$auth.me.organization" @updated="updated" />
</template>

<script>
import OrganizationDetail from '~/pages/shared/organizations/OrganizationDetail';

export default {
  components: {
    OrganizationDetail,
  },

  methods: {
    updated(newOrganization) {
      this.$auth.commit('SET_ORGANIZATION', newOrganization);
    },
  },
};
</script>
